* {
  padding: 0;
  margin: 0;
}
body {
  padding: 0;
  margin: 0;
}

.aboutUs-item-front,
.aboutUs-item-back {
  backface-visibility: hidden;
  transition: 0.6s;
  -webkit-transition: 0.6s;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  position: absolute;
}

.aboutUs-item-front {
  z-index: 2;
  transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  border-radius: 10px;
  float: left;
  height: auto;
  width: 100%;
}

.aboutUs-item-back {
  transform: rotateY(-180deg);
  -webkit-transform: rotateY(-180deg);
}

.flip-container {
  perspective: 500px;
  height: 400px;
  position: relative;
  margin-bottom: 30px;
  backface-visibility: visible;
}

.flip-container:hover .flipper,
.flip-container.hover .flipper {
  transform: rotateY(180deg);
}

.flip-container .flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  position: relative;
}
